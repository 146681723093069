jQuery( 'document' ).ready( function() {
    jQuery('.carousel').carousel({
        pause: 'hover'
    });
    jQuery('#carouselservice.carousel .item').each(function(){
        var next = jQuery(this).next();
        if (!next.length) {
            next = jQuery(this).siblings(':first');
        }
        next.children(':first-child').clone().appendTo(jQuery(this));

        if (next.next().length>0) {

            next.next().children(':first-child').clone().appendTo(jQuery(this)).addClass('rightest');

        }
        else {
            jQuery(this).siblings(':first').children(':first-child').clone().appendTo(jQuery(this));

        }
    });
    jQuery('p.question').on('click',function(){jQuery('.collapse').collapse('hide');});
    jQuery('body').on('click',function(e){
        if (e.target.id == "cart-links" || e.target.id == "cart-icon" || e.target.id == "cart-count" || e.target.id == "cart-divider" || e.target.id == "cart-amount" || e.target.id == "header-mini-cart"){
            jQuery('.header-mini-cart').show();
        } else {
            jQuery('.header-mini-cart').hide();
        }
    });
    jQuery('.ajax_add_to_cart').on('click',function(e){
        console.log('added');
        setTimeout(function() {
            jQuery('.header-mini-cart').show();
        }, 1000);

        setTimeout(function() {
            jQuery('.header-mini-cart').hide();
        }, 100000);

    });
    jQuery('.cro-block').matchHeight();
    jQuery('.post-block-wrapper').matchHeight();
    jQuery('.woocommerce-loop-product__title').matchHeight();
    jQuery('.services-page .content-wrapper .thumb-img').matchHeight();
    jQuery('.services-page .content-wrapper .content .h2').matchHeight();
    jQuery('.services-page .content-wrapper .content .summary').matchHeight();
    jQuery('.other-service-section .content-wrapper .thumb-img').matchHeight();
    jQuery('.other-service-section .content-wrapper .content .h2').matchHeight();
    jQuery('.other-service-section .content-wrapper .content .summary').matchHeight();
    jQuery('.ef-products-section ul.products li .attachment-woocommerce_thumbnail').matchHeight();
    jQuery('.our-services-logo .service-logo').matchHeight();
    jQuery('.why-choose-us .cro-icon').matchHeight();
    jQuery('.why-choose-us .cro-title').matchHeight();

    jQuery('.woocommerce-cart').on('click', '.minus', function (e) {
        console.log('added');
        var qty  = jQuery(this).parent().find('input.qty');
        var val  = parseInt(qty.val());
        var step = qty.attr('step');
        step     = 'undefined' !== typeof(step) ? parseInt(step) : 1;
        if (val > 0) {
            qty.val(val - step).change();
        }
    });
    jQuery('.woocommerce-cart').on('click', '.plus', function (e) {
        var qty  = jQuery(this).parent().find('input.qty');
        var val  = parseInt(qty.val());
        var step = qty.attr('step');
        step     = 'undefined' !== typeof(step) ? parseInt(step) : 1;
        qty.val(val + step).change();
    });
});